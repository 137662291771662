<template>
  <div class="plan-data">
    <div class="plan-title">
      <h3>{{ planData.name }}</h3>
      <div class="plan-price">
        <div class="clp">{{ formatPrice(planData.priceCLP) }}</div>
        <div class="monthly">Mensual</div>
        <div class="extra">
          <span class="uf">UF {{ planData.priceUF }}</span
          >/Mes
        </div>
        <div class="corner"></div>
      </div>
    </div>
    <div class="plan-info">
      <h3 class="mb-2">{{ planData.title }}</h3>
      <img class="plan-image mb-2" :src="planData.image" />
      <span class="mb-2">+</span>
      <span class="mb-2">{{ planData.description }}</span>
      <a class="mb-4" style="font-weight: 600; font-size: 0.9rem">Conoce más</a>
      <v-btn color="#E8C665" elevation="0" @click="next()"> Lo Quiero! </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Plan",
  props: ["planData"],
  data: () => ({
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
  }),
  methods: {
    formatPrice(number) {
      return this.formatter.format(number);
    },
    next() {
      this.$emit("next-step");
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-data {
  background-color: #f6f8fa;
  display: inline-block;
  position: relative;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.25;
  width: 350px;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 2rem;

  .plan-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h3 {
      color: #464e71;
      font-weight: 600;
      font-size: 1.25rem;
      display: flex;
      align-items: center;
    }
    .plan-price {
      background-color: #2c6ea9;
      color: white;
      width: 138px;
      margin-top: -2rem;
      position: relative;
      display: flex;
      background: #2c6ea9;
      border: 1px solid #2c6ea9;
      border-radius: 0 0 6px 6px;
      width: 145px;
      height: 115px;
      padding: 1rem;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .clp {
        font-size: 1.5rem;
        font-weight: 600;
      }

      .monthly {
        font-size: 0.9rem;
      }

      .extra {
        .uf {
          font-weight: 600;
        }
      }

      .corner {
        position: absolute;
        margin-top: -0.1rem;
        top: 115px;
        left: -1px;
        width: 103px;
        height: 103px;
        background-color: #2c6ea9;
        transform-origin: top left;
        transform: scale(1, 0.25) rotate(-45deg);
        border-radius: 6px;
      }
    }
  }

  .plan-info {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      color: #464e71;
      font-weight: 500;
    }
  }
}
</style>